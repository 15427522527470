.signup-title {
  text-align: center;
  color: #4a4a4a;
  font-size: 2em;
  margin-bottom: 1em;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  max-width: 500px;
  margin: auto;
  border: 1px solid #ccc; /* Add a border */
  padding: 2em; /* Add some padding */
  background-color: #f9f9f9; /* Add a background color */
  border-radius: 4px; /* Optional: Add some border radius for rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some box-shadow for a subtle 3D effect */
}

.form-noteReg {
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  color: #666;
}
.error-message {
  color: red;
  /* Other styling as needed */
}

.wide-input-container {
  width: 100%; /* or any specific width */
}

.sign-in-box {
  width: 30%; /* Adjust width as needed */
  margin: 30px auto; /* Center the box */
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light gray background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: block;
}

/* ... other styles */

.sign-in-box a {
  color: #007bff; /* A common color for links */
  text-decoration: none;
  font-weight: 500; /* Slightly bolder to stand out */
}

.sign-in-box a:hover {
  text-decoration: underline; /* Underline on hover for better UX */
  color: #0056b3; /* Slightly darker color on hover */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* high z-index to ensure it's on top */
}

.form-label {
  font-size: 1em;
  color: #4a4a4a;
}

.form-input {
  width: 100%;
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.submit-btn {
  padding: 0.5em 1em;
  background-color: #004e9d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  width: 100%;
}
.submit-btn:hover {
  background-color: #003b7b; /* Slightly darker color on hover */
}
