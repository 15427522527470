/* Global Styles */
.implementation-status-body {
  font-family: "Roboto", sans-serif;
  background-color: #ecf0f1;
  color: #34495e;
}

.centered-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.centered-text {
  text-align: justify;
  font-size: 14px;
}

/* Form Styles */
.implementation-form {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f2f2f2; /* Updated to a light gray */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
}

.implementation-form:input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  /*border: 1px solid #bdc3c7; */
  border-radius: 4px;
  font-size: 16px;
}

.blue-button {
  background-color: #004e9d;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blue-button:hover {
  background-color: #ff8c00;
}

/* Status Section */
.status-section {
  max-width: 800px;
  margin: 50px auto;
}

.status-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.status-box {
  flex: 100%;
  text-align: center;
  border-radius: 6px;
}

.smaller-text {
  font-size: 0.8rem;
  color: black; /* or any other color that contrasts with your background */
  margin-top: 5px;
}

.status-card {
  flex: 1;
  min-width: 150px;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s; /* Added for hover effect */
}
.missing-status-card {
  flex: 1;
  min-width: 150px;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.missing-status-card:hover {
  transform: scale(1.02); /* Slightly enlarge card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
}

.status-card-content {
  font-size: 14px;
}

/* Color Indicators */
.green {
  background-color: #2ecc71;
  color: #ffffff;
}

.red {
  background-color: #e74c3c;
  color: #ffffff;
}

.yellow {
  background-color: #f39c12;
  color: #ffffff;
}

.navigateButton {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #0065b3;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  cursor: pointer;
}

.navigateButton:hover {
  background-color: #0065b3;
}

@media (max-width: 768px) {
  .centered-title {
    font-size: 20px; /* Smaller font for smaller screens */
  }

  .centered-text,
  .status-card-content {
    font-size: 12px; /* Adjust text size for readability */
  }

  .implementation-form {
    margin: 20px auto;
    padding: 15px;
  }

  .implementation-form:input[type="text"] {
    font-size: 14px; /* Adjust input font size */
  }

  .status-cards-container {
    gap: 10px; /* Reduce gap between cards */
  }

  .status-card,
  .missing-status-card {
    padding: 8px; /* Adjust padding */
    min-width: 120px; /* Adjust minimum width for better layout */
  }

  .navigateButton {
    padding: 8px 16px; /* Adjust button size */
  }
}
