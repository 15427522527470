.user-search-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.user-search-container h2 {
  color: #333;
}

.user-search-container p {
  color: #666;
  line-height: 1.5;
}

.user-search-container input[type="email"],
.user-search-container input[type="password"],
.user-search-container select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* high z-index to ensure it's on top */
}

.user-search-container button {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 10px;
  margin-right: 10px; /* New property to add space between buttons */
  color: #fff;
  background-color: #ff8c00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.user-search-container button:hover {
  background-color: #0056b3;
}
