.forms-container {
  display: flex;
  flex-direction: column; /* Stacks the children elements vertically */
  justify-content: space-between;
  align-items: center;
}

.upload-components {
  display: flex;
  flex-wrap: wrap;
}

.group-number {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
}

.form-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600; /* Makes the label text slightly bolder */
}

.form-field input[type="text"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
}

.form-field.section-buttons {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.section-buttons button {
  flex-grow: 1;
  margin: 5px;
  text-align: center;
  background-color: #004e9d;
  color: white;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* high z-index to ensure it's on top */
}

.form-field label {
  display: block;
  margin-bottom: 5px;
}

.form-field input[type="text"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.upload-form {
  order: 2; /* This will ensure that .upload-form is in the middle */
  flex: 1;
  margin: 0 10px;
  padding: 20px;
  background: #f2f2f2;
  border-radius: 10px;
}

.upload-form input[type="file"] {
  width: 100%;
  border: 1px solid #ccc;
}

.submit-button {
  order: 3; /* This will ensure that .submit-button is at the bottom */
  display: block;
  width: 200px;
  margin: 0 auto;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #004e9d;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2em;
}

.submit-button:hover {
  background-color: #ff8c00;
}

.section-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.info-bubble {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #3498db;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: fixed;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.info-bubble:hover + .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.label-info-wrapper {
  display: flex;
  align-items: center;
}

.yo {
  margin-right: 5px; /* Add some space between the label and info bubble */
}
