body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

h1 {
  color: black;
}

p {
  color: black;
}
.headerEmpMain {
  background-color: #ddd;
  width: 500px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

form {
  display: grid;
  gap: 20px;
  width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

form label {
  color: black;
  display: flex;
  flex-direction: column;
}

form input {
  padding: 10px;
  border: 1px solid #004e9d;
  border-radius: 5px;
}

form button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #004e9d;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blue-button {
  background-color: #004e9d;
  color: white;
  transition: background-color 0.3s ease;
}

.back-button {
  position: relative;
  right: 900px;
  background-color: #ff8c00;
  color: white;
}

/* ... (rest of your CSS) ... */

.button-group {
  display: flex;
  justify-content: space-between;
  width: 90%; /* Decreased from 100% to 90% to bring buttons closer together */
  padding: 0 20px;
  margin-bottom: 20px;
}

.paginationHolder {
  display: flex;
  justify-content: flex-end;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* high z-index to ensure it's on top */
}

/* ... (rest of your CSS) ... */

.back-button2 {
  background-color: #ff8c00;
  color: white;
  transition: background-color 0.3s;
}

.back-button2:hover {
  background-color: #e07a00;
}

.button-termin {
  padding: 10px 15px;
  background-color: #ff8c00;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.button-termin:hover {
  background-color: #e07a00;
  transform: scale(1.05);
}

.blue-button:hover {
  background-color: #0065b3;
}

form button:hover {
  background-color: #e07a00;
}

.navigateButton {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #0065b3;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  cursor: pointer;
}
.navigateButton2 {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff8c00;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  cursor: pointer;
}

.navigateButton:hover {
  background-color: #0065b3;
}

.centerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerDiv2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px; /* To add space between buttons */
}

.centerDiv3 {
  display: flex;
  flex-direction: row; /* Changed from column to row to layout buttons horizontally */
  justify-content: flex-end; /* Aligns items to the right side */
  align-items: center; /* This will vertically center the buttons in case the container has a larger height */
  gap: 10px; /* To add space between buttons */
  padding-right: 20px;
}

@media (max-width: 600px) {
  .headerEmpMain,
  form {
    width: 100%; /* Adjusts width to full viewport width */
    margin: 10px auto; /* Reduces margin */
    padding: 10px; /* Adjusts padding */
  }

  .back-button {
    position: relative;
    right: auto; /* Resets positioning for mobile */
  }

  .button-group,
  .paginationHolder,
  .centerDiv,
  .centerDiv2,
  .centerDiv3 {
    flex-direction: column; /* Stacks elements vertically */
    justify-content: center; /* Centers items vertically */
    align-items: center; /* Aligns items horizontally */
    width: 100%; /* Adjusts width for mobile */
    padding: 10px 0; /* Adjusts padding */
  }
}
