.payment-container {
  padding: 1rem;
  position: relative;
  width: 100%; /* Adjusted for mobile screens */
  max-width: 500px; /* Ensures container doesn't exceed this width on larger screens */
  margin: auto;
  border-radius: 10px;
  background-color: #fdfdfd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  top: 80%; /* Adjusted to lower the container */
  transform: translateY(-20%); /* Adjust if necessary to fine-tune vertical position */
}

.payment-buttons-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically on small screens */
  gap: 0.5rem; /* Reduced gap for smaller screens */
}

.payment-button {
  padding: 1rem; /* Reduced padding for smaller screens */
  font-size: 1rem; /* Reduced font size for smaller screens */
  border: none;
  cursor: pointer;
  background-color: #ee790b;
  color: white;
  width: 100%; /* Ensure buttons expand to container width */
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .payment-container {
    padding: 2rem;
    top: calc(100% - 100px); /* Adjust to lower the container on larger screens */
    width: 80%;
    max-width: 800px;
  }

  .payment-buttons-container {
    flex-direction: row; /* Horizontal layout for larger screens */
    gap: 1rem;
  }

  .payment-button {
    padding: 1.2rem 2.5rem; /* Restored padding for larger screens */
    font-size: 1.2rem; /* Restored font size for larger screens */
  }
}

.copyright {
  margin-top: auto;
}

.copyright-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0.5rem; /* Added padding for better visibility */
  /* background: rgba(255, 255, 255, 0.9); */
}

.payment-container h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.parent-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.payment-button:hover {
  background-color: #1217a5;
}
