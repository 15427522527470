@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

html,
body {
  /*height: 100%;*/
  margin: 0;
  /*padding: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-image: url(/Users/abrarhoque/Documents/Abrar-projects/react-firebase-auth/src/components/assets/OptiMed_Background.png);
  background-size: 10%; /* This will make the image 50% of its original size. Adjust the value to fit your needs. */
  /* background-repeat: no-repeat; /* This will prevent the image from repeating. */
  /* background-position: center; /* This will center the image in the body. */
}

#root {
  /*height: 100%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
