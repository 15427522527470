/* Combined and updated CSS */

/* Variables (if needed) */
:root2 {
  --color-primary: #yourColorHere;
  --color-bg: #yourColorHere;
  --color-white: #ffffff;
}

/* Your existing footer styles */
.footer2 {
  background: var(--color-primary);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

.footer2 a {
  color: var(--color-bg);
}

.footer__logo2 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer__socials2 {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.footer__socials2 a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials2 a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer__copyright2 {
  margin-bottom: 4rem;
  color: var(--color-bg);
}

/* Copyright2.css */

.copy2 {
  text-align: center; /* Center align the text */
  flex-grow: 1; /* Allow it to take up all available space */
  margin-left: 400px; /* Add left margin to shift the text to the right */
}

/* Your Copyright.css styles */
.footer-style2 {
  bottom: 5px;
  background-color: rgba(128, 128, 128, 0.7); /* Update this if you want to use var(--color-primary) */
  margin-bottom: 0;
  padding-bottom: 12px;
}

.first-div-style2 {
  padding-bottom: 1px;
}

/* Copyright2.css */
.container-style2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.img-style2 {
  width: 25%;
  margin-left: 10px;
}

@media (max-width: 768px) {
  /* Footer Styles */
  .footer {
    background: var(--color-primary);
    padding: 2rem 1rem; /* Adjusted for mobile */
    text-align: center;
    font-size: 0.8rem; /* Smaller font size for mobile */
    margin-top: 5rem; /* Adjusted margin */
  }

  .footer a {
    color: var(--color-bg);
  }

  .footer__logo {
    font-size: 1.5rem; /* Adjusted size for mobile */
    font-weight: 500;
    margin-bottom: 1.5rem; /* Adjusted margin */
    display: inline-block;
  }

  .permalinks {
    display: flex;
    flex-direction: column; /* Column layout for mobile */
    gap: 1rem; /* Adjusted gap */
    margin: 0 auto 2rem;
  }

  .footer__socials {
    display: flex;
    justify-content: center;
    gap: 0.5rem; /* Adjusted gap for mobile */
    margin-bottom: 2rem; /* Adjusted margin */
  }

  .footer__socials a {
    padding: 0.5rem; /* Adjusted padding */
    border-radius: 0.5rem; /* Adjusted border-radius */
    display: flex;
    border: 1px solid transparent;
  }

  .footer__socials a:hover {
    color: var(--color-bg);
    border-color: var(--color-bg);
  }

  .footer__copyright {
    margin-bottom: 2rem; /* Adjusted margin */
    color: var(--color-bg);
  }

  /* Adjustments for Copyright Elements */
  .copy {
    text-align: center;
    margin-left: 0; /* Removed left margin for mobile */
  }

  .footer-style {
    background-color: rgba(128, 128, 128, 0.7); /* Consider using var(--color-primary) */
    margin-bottom: 0;
    padding-bottom: 10px; /* Adjusted padding */
  }

  .first-div-style {
    padding-bottom: 0; /* Adjusted padding */
  }

  .container-style {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: center; /* Center-align items */
  }

  .img-style {
    width: 50%; /* Adjusted width for mobile */
    margin: 0 auto; /* Center image */
    margin-bottom: 1rem; /* Added bottom margin */
  }
}
