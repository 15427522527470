.OptiEnhance {
  /* padding: 20px;
  padding-bottom: 120px; */
  display: flex;

  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.optiEnhance-input {
  margin: 10px;
  padding: 8px;
  font-size: 16px;
}

.form-Opti {
  width: 1200px;
}

/* ... [other styles] */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* high z-index to ensure it's on top */
}

.OptiEnhance h2 {
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

/* .copyright {
  margin-top: auto;
} */

/* .copyright-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
} */

/* .copyright-fixed {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff; 
  padding: 20px 0;
  text-align: center;
  z-index: 1000;
} */

/* .copyright-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1000;
} */

/* .copyright-fixed23 {
  margin-top: auto;
} */
@media (max-width: 768px) {
  .form-Opti {
    /* Adjust width for smaller screens */
    width: 100%;
    /* Remove fixed padding and allow it to be responsive */
    padding: 0 10px;
  }

  /* Further adjustments can be added here for other elements as needed */
}
