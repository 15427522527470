body {
  margin: 0;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.home-container h1 {
  color: #333;
  font-size: 2em;
  text-align: center;
  margin-top: 50px;
}

.home-container p {
  color: #666;
  font-size: 1.2em;
  text-align: center;
  margin-top: 20px;
}

.button-containerHomeScreen {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.button-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desc {
  font-size: 22px;
}

@font-face {
  font-family: roboto;
  src: url("fonts/RobotoMono-VariableFont_wght.ttf");
}
.roboto {
  font-family: roboto;
}

.cardFirst {
  background-color: #f9f9f9; /* Light background for the card */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px;
  text-align: center; /* Center align text */
  margin: 20px auto; /* Center the card with automatic horizontal margins */
  max-width: 800px; /* Maximum width of the card */
}

.cardFirst h1 {
  color: #333; /* Dark text for readability */
  font-size: 44px; /* Adjust size as needed */
  margin-bottom: 15px;
}

.cardFirst .desc {
  color: #555; /* Slightly lighter color for the description */
  font-size: 20px; /* Adjust size as needed */
}

/*.title {
    border: 1px solid #000;
    display: inline-block;
    padding: 10px;
    text-align: center;
    margin: 0 auto;
    background-color: #cccccc;
    border-radius: 15px; 
  }*/

.home-container {
  text-align: center;
}

.logo-style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  margin-top: 40px;
}

.button-style1 {
  background-color: #004e9d;
  color: #ffffff;
  padding: 20px 30px;
  font-size: 16px;
}

.button-style1:hover {
  background-color: #00008b;
}

.button-style2 {
  background-color: #ff8c00;
  color: #ffffff;
  padding: 20px 30px;
  font-size: 16px;
}

.button-style2:hover {
  background-color: #ff8c00;
}

.image-style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  margin-top: 80px;
}
