.side-nav-bar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff; /* White background */
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Add some shadow for a '3D' effect */
}

.side-nav-bar a {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Add some shadow for a '3D' effect */
  padding: 12px 15px; /* Adjusted padding */
  text-decoration: none;
  font-size: 25px;
  color: #000; /* Black text */
  display: block;
  transition: 0.3s;
  background-color: #ffffff; /* White background */
}

.side-nav-bar a:hover {
  color: #ffffff; /* White text */
  background-color: #000; /* Black background */
}

.side-nav-bar.open {
  width: 250px;
}

/* Existing styles ... */

.main-content {
  transition: margin-left 0.5s;
}

.main-content.shifted {
  margin-left: 250px; /* Same width as the side-nav-bar when open */
}

/* Existing styles ...   #fda50d */

.dropdown-content {
  overflow: hidden;
  max-height: 0; /* Initially set to 0 to hide the content */
  background-color: #ffffff; /* White background */
  padding: 0;
  margin: 0;
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Add some shadow for a '3D' effect */
  /* Add a transition for the max-height property */
  transition: max-height 0.5s ease-out;
}

.dropdown-content a {
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Add some shadow for a '3D' effect */
  color: #000; /* Black text */
  padding: 8px 15px; /* Same as side-nav-bar */
  text-decoration: none;
  display: block;
  font-size: 20px; /* Smaller to differentiate from main links but still consistent */
  background-color: #ffffff; /* White background */
}

.dropdown-content a:hover {
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Add some shadow for a '3D' effect */
  background-color: #ffcba4; /* Black background */
  color: #ffffff; /* White text */
}

/* When active, set a specific max-height to reveal the content */
.show {
  max-height: 400px; /* Adjust this value based on your content's size */
}

.brand-image2 {
  margin-top: -75px;
  margin-left: 40px; /* Adjust this value as needed */
}

.icon-right {
  /*margin-left: 10px; /* adjust the space as needed */
  float: right;
}

/* other existing CSS rules, if any */
