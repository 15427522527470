.account-profile {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #f9f9f9;
  font-family: "Arial", sans-serif;
}

.account-profile h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.account-profile div {
  margin-bottom: 15px;
  font-size: 18px;
}

.account-profile label {
  font-weight: bold;
  margin-right: 10px;
}

.account-profile a {
  color: #007bff;
  text-decoration: none;
}

.account-profile a:hover {
  text-decoration: underline;
}
