/* Center and widen the table */
.super-app-theme--header {
  background-color: #004e9d;
  color: #ffffff;
}

/* Ensure the table container fills its parent and centers content */
.MuiDataGrid-root .MuiDataGrid-main {
  max-width: 100%; /* Use max-width for better control */
  margin: 0 auto; /* Center the table */
}

/* Responsive table adjustments */
@media (max-width: 768px) {
  .MuiDataGrid-root {
    /* Adjust table width on smaller screens */
    width: 100%;
    overflow-x: auto; /* Enables horizontal scrolling on small screens */
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    padding: 8px; /* Reduce cell padding on small screens */
    box-sizing: border-box; /* Ensure padding is included in the width and height */
  }
}

/* Additional styling to ensure table responsiveness and visibility */
.MuiDataGrid-root {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden; /* Ensures the shadow and radius are applied properly */
  padding: 16px; /* Optional: Add padding for overall spacing */
  box-sizing: border-box; /* Include padding in width and height calculations */
}
