@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

body {
  background-color: #f4f4f4;
  font-family: "Roboto", sans-serif;
}

form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
}

button {
  margin-top: 20px;
}

.groupNumber {
  visibility: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
}

body::before {
  content: "";
  background-image: url("Shutterstock_3.jpg");
  background-repeat: repeat;
  background-size: contain;
  background-position: center -50px; /* Shift up by 50 pixels */
  opacity: 0.4; /* Adjust opacity as per your needs */
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Roboto Mono";
  src: url("fonts/RobotoMono-VariableFont_wght.ttf") format("truetype");
}
.Roboto {
  font-family: "Roboto Mono";
}

@font-face {
  font-family: "Oxanium";
  src: url("fonts/Oxanium-Medium.ttf") format("truetype");
}
.Oxanium {
  font-family: "Oxanium";
}

.custom-navbar {
  background-color: #00008b;
  height: 70px;
}

/* in App.css */
.nav-item .nav-link:hover {
  color: #ff8c00 !important; /* use !important to override inline styles */
}

/* App.css */

.whole {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bigBoy {
  padding-top: 15px;
  flex: 1 0 auto;
}
