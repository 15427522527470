/* Dashboard.module.css */

/* Dashboard.module.css */

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff; /* change to preferred background color */
  border: 1px solid #ccc; /* change to preferred border color */
  border-radius: 10px; /* change to preferred border radius */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); /* optional drop shadow */
  width: 80%; /* adjust as needed */
  max-width: 800px; /* adjust as needed */
}

/* ... rest of the styles ... */

.dashboard h1 {
  color: #6c757d;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.dashboard p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

/* Dashboard.module.css */

.dashboard button {
  background-color: #004e9d;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;

  /* Add these lines */
  width: 350px; /* adjust as needed */
  height: 50px; /* adjust as needed */
  white-space: nowrap; /* prevents text from wrapping to next line */
  overflow: hidden; /* hides any text that doesn't fit */
  text-overflow: ellipsis; /* shows '...' for overflowing text */
}

.dashboard button:hover {
  background-color: #0069d9;
  color: white;
}

.dashboard button:active {
  background-color: #0062cc;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
