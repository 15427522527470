.role-classifier-container {
  width: 700px;
  max-height: 300px;
  overflow-y: scroll;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  margin: 0 auto; /* Horizontally center the component */
  /*position: absolute;
    bottom: 20px; /* Position the component 20px from the bottom */
  left: 0;
  right: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* high z-index to ensure it's on top */
}

.role-classifier-list {
  list-style-type: none;
  padding: 0;
}

.role-classifier-list-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.role-classifier-list-item select {
  margin-left: 10px;
}
