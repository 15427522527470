/* ClaimSubmissionForm.css */
.claim-submission-container {
  max-width: 800px; /* Increased the width from 600px to 800px */
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.claim-submission-title {
  font-size: 1.5em;
  text-align: center;
  color: #333;
}

.claim-submission-supporting-text {
  text-align: center;
  color: #333
}

.claim-submission-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border-radius: 4px;
  max-width: 850px;
  width: 100%;
}

.claim-submission-date-input {
  /* padding: 0.8rem; */
  border-radius: 4px;
  /* margin-bottom: 20px; */
  background: #f2f2f2;
}
.claim-submission-date-input:focus {
  border-color: #0056b3;
  outline: none;
}
.claim-submission-input {
  background: #f2f2f2;
}

.claim-submission-input,
.claim-submission-button {
  /* padding: 0.8rem; */
  /* margin-bottom: 6px; */
  border-radius: 4px;
  border: 1px solid #ddd;
}

.claim-submission-select {
  background: #f2f2f2;
  /* padding: 0.8rem; */
}
/* .claim-submission-label {
} */

.claim-submission-input:focus {
  border-color: #0056b3;
  outline: none;
}

.claim-submission-button.MuiButton-root {
  background-color: rgb(0, 78, 157);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.claim-submission-button:hover {
  background-color: #0056b3;
}

.claim-submission-captcha {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
