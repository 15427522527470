.navItemCustom {
  color: black;
  list-style-type: none; /* Remove bullet point */
  /*margin-right: 1rem; /* Add space to the right of each NavItem */
  font-size: 18px; /* Increase the font size */
}

.parent-container {
  margin-left: auto;
  margin-right: auto;
}

.navItemCustom2 {
  list-style-type: none; /* Remove bullet point */
  /* margin-left: 400px; /* Add space to the right of each NavItem */
  color: black; /* Note: this is CSS, not JavaScript. You don't need quotes around color names */
  font-size: 20px; /* Set font size here. You can adjust this as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .navItemCustom2 {
    margin-left: 0;
  }
}

.brand-image {
  max-width: 100%;
  height: auto;
}

/* Header.css */

/* Header.css */

/* Header.css */
/* In Header.css or another suitable CSS file */
.customNavbar {
  background-color: #333; /* Change this to your desired color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Add some shadow for a '3D' effect */
}

.custom-toggler {
  border-color: black;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.dark-blue-navbar {
  background-color: #004e9d; /* Navy Blue */
}

.dark-blue-navbar a,
.dark-blue-navbar .navbar-text {
  color: #ffffff; /* White color for better contrast */
}

.collapse-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
