/* Basic table styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 2em 0;
  font-family: Arial, sans-serif;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.header {
  background-color: #ddd;
  width: 500px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

th,
td {
  padding: 12px 15px;
  text-align: left;
}

th {
  background-color: #004e9d;
  color: #ffffff;
  letter-spacing: 0.1em;
}
.myTabs {
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); /* Example box shadow - adjust as necessary */
}

/* Styles for rows - zebra pattern */
tr:nth-child(even) {
  background-color: #cccccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

tr {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.table-row {
  border-bottom: 1px solid #cccccc; /* Adds a border to the bottom of each row */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

/* .table-row:nth-child(even) {
  background-color: #f9f9f9;
} */

.table-cell {
  padding: 10px;
}

/* Add this style in your CSS file */
.tabs {
  display: flex;
  justify-content: flex-end; /* Aligns items to the right */
  gap: 10px; /* Optional: adds some space between elements */
}

.tabLeft {
  display: flex;
}

.tab {
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.tabGroup,
.tabActions {
  display: flex;
  gap: 10px; /* Adjust the space between items */
}

.tabsContainer,
.tabsContainer2 {
  display: flex;
  justify-content: space-between; /* This separates the children to each side */
  align-items: center;
  padding-left: 10px; /* Applied to both containers for consistency */
}

.tab.active {
  background-color: #e0e0e0;
}

/* Styles for pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin: 2em 0;
}

.pagination button {
  margin: 0 0.25em;
  padding: 10px 20px;
  border: none;
  background-color: #004e9d;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 0.8em;
}

.pagination button:hover {
  background-color: #ff8c00;
}

.pagination button:active {
  background-color: #3e8e41;
}

/* modalStyles.css */

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 25%;
  height: 50%;
  /* You can add more styles as needed */
}

.modal-content3 {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.modal-content3 h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-content3 label {
  display: block;
  margin-bottom: 10px;
}

.modal-content3 input[type="text"] {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content3 button {
  padding: 10px 15px;
  margin: 10px 5px 0;
  border: none;
  background-color: #004e9d;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-content3 button:hover {
  background-color: #add8e6;
}

.modal-content3 button[type="submit"] {
  background-color: #ff8c00;
}

.modal-content3 button[type="submit"]:hover {
  background-color: #ffdab9;
}

.modal-content2 {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 25%;
  height: 25%;
  /* You can add more styles as needed */
}

form {
  width: 90%; /* Adjust as needed */
  height: auto;
  overflow-y: auto;
}

.large-iconData {
  font-size: 28px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%; /* Adjust the width as per requirement */
  height: 60%; /* Adjust the height as per requirement */
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.hover-buttonDataComp {
  background-color: #e0e0e0; /* Light gray color */
  border: none;
  padding: 8px 16px;
  border-radius: 4px; /* Optional: for rounded corners */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333; /* Dark gray text color */
}

.hover-buttonDataComp:hover {
  transform: scale(1.05); /* Adjust scale value as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #d0d0d0; /* Slightly darker gray when hovered */
}

.no-results-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Reduced height */
  margin: 10px;
  padding: 10px;
  font-size: 1em; /* Adjusted font size */
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-weight: bold; /* Make text bold */
}

/*---------------------------------------------------------*/

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-card-form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* or any space you see fit */
}

.form-group {
  margin-bottom: 1rem;
}

.flex-containerEMP {
  display: flex;
  align-items: center;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.btn-confirm,
.btn-cancel {
  padding: 0.5rem 1rem; /* Adjust the padding to your needs */
  margin: 0 0.5rem; /* Spacing between buttons */
  cursor: pointer;
}
.table-wrapper {
  overflow-x: auto; /* Enables horizontal scrolling */
  width: 100%; /* Adjust based on your layout needs */
  margin: 20px 0; /* Optional, for spacing */
}

/* Existing styles for the table */
table {
  width: auto; /* Change from 100% to auto to allow table to expand horizontally */
  border-collapse: collapse;
  margin: 2em 0;
  font-family: Arial, sans-serif;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

/* Add other existing CSS styles here as needed */

@media (max-width: 768px) {
  .header,
  .modal-content,
  .modal-content2,
  .modal-content3,
  .modal {
    width: 90%; /* Adjust width to fit mobile screens */
    margin: 10px auto; /* Center modal on mobile */
  }

  .modal-content,
  .modal-content2,
  .modal-content3 {
    height: auto; /* Adjust height based on content */
  }

  .table-cell,
  th,
  td {
    padding: 8px; /* Reduce padding for smaller screens */
  }

  .pagination button {
    padding: 8px 10px; /* Adjust padding for smaller touch targets */
    margin: 5px; /* Reduce margin to fit more content */
  }

  .pagination {
    flex-wrap: wrap; /* Allow these elements to wrap on small screens */
    justify-content: center; /* Center content for better appearance */
  }
  .tabs {
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 0 10px; /* Adds padding to the sides */
  }

  .tabLeft,
  .tabGroup,
  .tabActions,
  .tabsContainer,
  .tabsContainer2 {
    flex-direction: column;
    align-items: center; /* Aligns items to the center */
    gap: 5px;
    width: 100%; /* Ensures the width fits the screen */
    max-width: 500px; /* Sets a max-width to avoid stretching too wide */
    margin: 0 auto; /* Centers the elements within the page */
    padding: 0 10px; /* Adds some padding to avoid touching the screen edges */
  }

  .tab {
    margin: 5px 0; /* Adjusts spacing for a tighter layout */
    padding: 8px 10px; /* Slightly reduces padding for smaller screens */
    width: calc(100% - 20px); /* Ensures padding is accounted for in width */
  }
}
