.admin-portal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 20px; /* Add padding at the top */
  padding-bottom: 20px; /* Add padding at the bottom */
}

.admin-portal-container > div {
  margin-top: 20px;
}

/* AdminPortal.css */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* high z-index to ensure it's on top */
}

.headerAdminCard {
  background-color: #ddd;
  width: 300px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.adminUserNote {
  font-size: 12px; /* Smaller font size for note */
  color: black; /* Lighter text color for note */

  bottom: 10px; /* Positioning from bottom */
  right: 15px; /* Positioning from right */
}

.back-buttonAddUser {
  position: relative;
  right: 900px;
  background-color: #ff8c00;
  color: white;
}

.form-style {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.input-style {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.select-style {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.button-style {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #004e9d;
  color: white;
  cursor: pointer;
}

.section-style {
  margin-top: 20px;
}

.section-style2 {
  margin-top: 20px;
  width: 80%; /* set width to 80% of the parent container */
}
