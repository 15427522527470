.centered-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  height: 100vh; /* Take full viewport height */
  width: 100%;
  text-align: center;
}

.boxStyle {
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 150px;
  background-color: rgba(196, 192, 192, 0.926);
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; /* Centers the box in both axes */
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.boxStyle::after {
  content: "";
  display: table;
  clear: both;
}

.boxStyle h3,
.boxStyle h5,
.boxStyle i {
  display: block;
}

.boxStyle h2 {
  color: black;
}

.boxStyle i {
  color: black;
}

/* If you want to add specific spacing, you can do so individually */
.card h4,
.card p {
  /*margin-bottom: 2px;  Example: 10px bottom margin for headings and paragraphs */
}

.card form {
  margin-top: 0px; /* Example: 10px top margin for the form */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .boxStyle {
    width: 90%; /* Adjust width for smaller screens */
    height: auto; /* Optional: Adjust height as per content */
    padding: 20px; /* Add some padding on smaller screens */
  }

  .centered-container {
    height: auto; /* Adjust viewport height for mobile devices */
    padding: 20px; /* Add some padding */
  }

  /* If you have specific styles for .card, adjust them here for mobile view */
  .card h4,
  .card p {
    margin-bottom: 10px; /* Adjust spacing for smaller screens */
  }

  .card form {
    margin-top: 20px; /* Adjust form spacing for better readability */
  }
}
