/* Styles.css */

.myProj-body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: #f4f7f6; */
  margin: 0;
  padding: 20px;
  color: #333;
}

.myProj-h1 {
  text-align: center;
  color: #2c3e50;
  font-size: 2em;
  font-weight: bold;
  margin-top: 0.5em;
  margin: 20px auto;
  margin-bottom: 0.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  background-color: #f4f7f6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
  max-width: 80%;
  overflow-wrap: break-word;
}

.myProj-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.myProj-table,
.myProj-th,
.myProj-td {
  border: 1px solid #ddd;
}

.myProj-th {
  background-color: #004e9d;
  color: white;
  padding: 10px;
  text-align: left;
}

.myProj-td {
  padding: 10px;
  text-align: left;
  vertical-align: middle;
}

.myProj-tr:nth-child(even) {
  background-color: #f2f2f2;
}

.myProj-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.myProj-button:hover {
  background-color: #2874a6;
}

.myProj-input[type="text"] {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: calc(100% - 22px); /* Adjust input width to account for padding and border */
}

.myProj-div {
  text-align: center;
}

.myProj-search-container {
  margin-bottom: 20px;
  /* text-align: center;  */
}

.myProj-search-input {
  width: 140px; /* Reduced width for the search input */
  display: inline-block; /* Ensures the input is displayed inline */
  margin-right: 10px; /* Adds a small margin between the input and the button */
}

.myProj-search-button {
  display: inline-block; /* Ensures the button is displayed inline with the input */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .myProj-th,
  .myProj-td {
    display: block;
    width: 100%;
  }

  .myProj-tr {
    margin-bottom: 10px;
  }

  .myProj-td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .myProj-td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    top: 10px;
    font-weight: bold;
    text-align: left;
  }

  .myProj-search-input,
  .myProj-search-button {
    width: 100%;
    margin: 10px 0;
  }
}
