/* FileUpload.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-top: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.buttonAdd {
  background-color: #004e9d;
  color: white;
}

.buttonAdd:hover {
  background-color: #3366cc;
}
.commonButton {
  width: 350px;
  height: 40px;
  overflow: hidden;
  box-sizing: border-box; /* This ensures that padding and border are included in the total width/height */
}

.buttonSubmit {
  background-color: #004e9d;
  color: white;
}

.Mainbutton {
  background-color: #004e9d;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;

  /* Add these lines */
  width: 350px; /* adjust as needed */
  height: 60px; /* adjust as needed */
  white-space: nowrap; /* prevents text from wrapping to next line */
  overflow: hidden; /* hides any text that doesn't fit */
  text-overflow: ellipsis; /* shows '...' for overflowing text */
}
.Mainbutton:hover {
  background-color: #0069d9;
  color: white;
}

.buttonSubmit:hover {
  background-color: #004e9d;
}

.buttonClear {
  background-color: #004e9d;
  color: white;
}

.buttonClear:hover {
  background-color: #004e9d;
}

.fileList {
  list-style-type: none;
  padding: 0;
}

.fileItem {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
