body {
  background-color: #f4f4f4;
  font-family: "Roboto", sans-serif;
}

form {
  max-width: 800px; /* Adjust this value as needed */
  margin: 10px auto; /* Adjust the top margin as needed */
  padding: 20px;
}

button {
  margin-top: 20px;
}

.blue-button {
  background-color: #004e9d;
  color: white;
  transition: background-color 0.3s ease;
}

.navigate-button {
  position: relative;
  left: 30px;
  background-color: #004e9d;
}

.navigate-button:hover {
  background-color: #ff8c00;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
}

/* enroll.css */

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  form {
    padding: 10px; /* Reduce padding for smaller devices */
    margin: 10px; /* Adjust margins to fit small screens */
  }

  .form-container,
  .title-container {
    flex-direction: column; /* Stack form elements vertically on small screens */
    align-items: center; /* Center-align items for a neat look */
  }

  .navigate-button {
    left: 0; /* Adjust button positioning on smaller screens */
    margin-bottom: 10px; /* Provide some space below the button */
  }
}

@media (max-width: 1024px) {
  form {
    max-width: 90%; /* Allow the form to be more fluid on tablet devices */
  }

  .navigate-button {
    left: 10px; /* Slightly adjust button position for medium screens */
  }
}
