.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
  gap: 20px;
  color: #34495e;
}

.card {
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Updated box-shadow */
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  transition: background-color 0.3s, box-shadow 0.3s;
  flex-basis: calc(20% - 20px); /* Reduced size and adjusted calculation */
  width: 200px; /* Reduced from 300px to 200px */
  margin: 10px;
}

.card h2 {
  text-align: center; /* Center align the title text */
  /* You can add other styling as needed */
}

.card-iconHome svg {
  font-size: 250px;
  display: block; /* To center it in the card */
  margin: 0 auto; /* Auto margins for horizontal centering */
  padding-bottom: 20px; /* Spacing from the element below */
  /* Add any other styles you want for your icons here */
}

/* styles.css or your CSS file/module */
.large-icon {
  font-size: 110px;
}

.card:hover {
  background-color: #d3d3d3 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.section {
  margin-bottom: 40px; /* or any space you want between sections */
}

.section-title {
  font-size: 28px; /* Slightly increased font size */
  margin-bottom: 30px; /* Increased bottom margin */
  text-align: center; /* Center aligned text */
  color: #333; /* Dark gray color */
  font-weight: 600; /* Semi-bold weight */
  letter-spacing: 1px; /* Added some letter spacing */
  text-transform: uppercase; /* Uppercase letters */
  padding-top: 20px; /* Padding at the top for spacing */
  border-top: 3px solid #ccc; /* A subtle top border */
}

.tooltip {
  visibility: hidden;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.card:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.card h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.card p {
  font-size: 18px;
}

.card-image {
  width: 100%;
  height: auto;
}
@media (max-width: 1650px) {
  .card-iconHome svg {
    font-size: 195px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 1450px) {
  .card-iconHome svg {
    font-size: 150px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 1350px) {
  .card-iconHome svg {
    font-size: 140px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 1130px) {
  .card-iconHome svg {
    font-size: 120px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 968px) {
  .card-iconHome svg {
    font-size: 100px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 768px) {
  .cards-container {
    padding: 20px;
  }

  .card {
    flex-basis: calc(50% - 20px);
    width: auto;
    margin: 5px;
  }

  .section-title {
    font-size: 22px;
    margin-bottom: 20px;
    padding-top: 10px;
  }

  .large-icon {
    font-size: 80px;
  }

  .card-iconHome svg {
    font-size: 50px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 480px) {
  .card {
    flex-basis: 100%;
    margin: 10px 0;
  }

  .section-title {
    font-size: 18px;
  }

  .card-iconHome svg {
    font-size: 40px; /* Further reduced size for small screens */
  }
}

.commissions-button {
  background-color: #004e9d;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
  position: fixed;
    bottom: .5rem;
    right: .5rem;

  /* Add these lines */
  width: 18rem; /* adjust as needed */
  height: 5rem; /* adjust as needed */
  white-space: nowrap; /* prevents text from wrapping to next line */
  overflow: hidden; /* hides any text that doesn't fit */
  text-overflow: ellipsis; /* shows '...' for overflowing text */
}
.commissions-button:hover {
  background-color: #0069d9;
  color: white;
}