.status-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.status-box {
  flex: 100%;
  text-align: center;
  border-radius: 6px;
  background-color: whitesmoke;
  border: 1px solid #ddd;
  box-shadow: 24;
  padding: 4;
  max-width: 1200px;
  margin: auto;
}
.group-setup {
  width: 90%; /* Adjust as needed */
  height: auto;
  overflow-y: auto;
  display: grid;
  gap: 20px;
  background-color: #ddd;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 500px;
}
.renewal-options {
  background-color: #f3f6f9;
  width: 100%;
  height: auto;
  overflow-y: auto;
  display: grid;
  gap: 20px;
  background-color: #ddd;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 500px;
}



.renewal-form-text-fields {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
}

.group-setup-submit-button-group {
  display: inline-flex;
  text-align: center;
  position: relative;
}

.renewal-label {
  margin-left: 0;
  margin-bottom: 0;
  flex-direction: row;
}

.submit-buttons {
  width: 90%;
}

.reenroll-options-divider {
  padding-top: 1em;
}
