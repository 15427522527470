/* Container for the overall layout */
.content-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px; /* Adjust the space between the elements */
  justify-content: center;
  margin: 20px auto; /* Center the container */
  max-width: 1400px; /* Set a max-width for the container */
}

/* GAP Group Supplemental Component styles */
.gap-group-container {
  font-family: "Helvetica Neue", Arial, sans-serif;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1;
  min-width: 300px; /* Minimum width of the component container */
}

.gap-group-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.gap-group-icon {
  /* Replace with your icon path */
  background-image: url("components/gapPage/gap-group.svg");
  background-size: 70%;
  background-repeat: no-repeat;
  background-color: #0c2659;
  background-position: center;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 10px;
}

.gap-group-title {
  font-size: 28px;
  font-weight: 700;
  color: #0c2659; /* Deep blue for the title */
  font-family: "JetBrains Mono Bold"; /* Assuming the font is JetBrains Mono Bold */
}

.gap-group-body {
  background-color: #fff;
  border-radius: 8px;
  /* font-size: 28px; */
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.gap-group-subtitle {
  font-size: 42px;
  font-weight: 600;
  font-family: "DM Sans Regular";
  color: #333; /* Dark grey for subtitle */
}

.gap-group-text {
  font-size: 24px;
  line-height: 1.6;
  color: #666; /* Lighter grey for the body text */
}

/* YouTube Video Component styles */
.video-container {
  flex: 1;
  min-width: 300px; /* Minimum width of the video container */
}

.responsive-iframe {
  width: 100%;
  height: 215px; /* You can adjust the height as needed */
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .content-container {
    gap: 20px;
  }

  .responsive-iframe {
    height: 400px; /* Larger height for larger screens */
  }
}

.second-content-container {
  display: flex;
  align-items: flex-start; /* Align to the top if cards have different heights */
  justify-content: space-between;
  margin: 20px auto;
  max-width: 1400px; /* Set a max-width for the container */
  gap: 20px; /* Add some space between the two cards */
}

/* Card style for the graphic container */
.graphic-container {
  flex: 1;
  background-color: #fff; /* White background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  justify-content: center; /* Center the graphic inside the card */
  align-items: center;
}

/* Card style for the text section */
.text-section {
  flex: 2;
  background-color: #fff; /* White background */

  padding: 120px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  flex-direction: column; /* Stack the text vertically */
}

.text-header {
  font-size: 14px;
  font-weight: bold;
  color: #fda50d;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.text-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.text-body {
  font-size: 16px;
  color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .second-content-container {
    flex-direction: column;
  }

  .graphic-container,
  .text-section {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px; /* Add space between stacked cards */
  }
}

.third-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 1400px; /* Adjust to your preference */
  gap: 20px; /* Space between text and image */
}

.text-container {
  flex: 1;
  padding: 20px;
  background-color: #fff; /* White background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.text-title2 {
  font-size: 18px;
  color: #fda50d; /* Dark text for the title */
  margin-bottom: 20px;
}

.text-body2 {
  font-size: 16px;
  color: #666; /* Lighter text for the body */
  margin-bottom: 20px;
}

.learn-more-button {
  padding: 10px 20px;
  background-color: #0c2659; /* Button color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.copyright-fixed23 {
  margin-top: auto;
  /* margin-top: 0; */
}

.image-container {
  flex: 1;
  position: relative;
}

.responsive-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.geometric-overlay {
  position: absolute;
  top: 0;
  right: 0;
  border-style: solid;
  border-width: 100px 100px 0 0; /* Adjust the size of the triangle */
  border-color: #ffd700 transparent transparent transparent; /* Triangle color */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .third-content-container {
    flex-direction: column;
  }

  .text-container,
  .image-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .geometric-overlay {
    border-width: 50px 50px 0 0; /* Smaller triangle on smaller screens */
  }
}

.fourth-content-container {
  background-color: #0c2659; /* Dark blue background color */
  color: #ffffff; /* White text color */
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.solution-details-title {
  font-size: 32px;
  margin-bottom: 20px;
  text-align: left; /* Align the title text to the left */
  position: relative;
  width: 1400px;
  padding-left: 200px; /* Padding to align with the columns */
}

.solution-details-title::after {
  content: "";
  position: absolute;
  left: 200px; /* Align the line with the start of the title text */
  bottom: -10px;
  width: calc(100% - 200px); /* Adjust width to account for padding */
  height: 4px; /* Thickness of the horizontal line */
  background-color: #ffffff; /* White line color */
}

.columns-container {
  display: flex;
  justify-content: space-between;
  width: 70%; /* Full width to spread the columns */
  margin-top: 20px; /* Space between title and columns */
  padding-left: 180px;
}

.column {
  width: 50%; /* Each column takes up half the width of the container */
  padding: 0 20px; /* Space inside each column */
}

.column-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase; /* Uppercase titles */
  color: #fda50d;
}

.column-text {
  font-size: 16px;
  line-height: 1.6;
  color: #f0eeec;
  margin-bottom: 20px; /* Space between items */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .columns-container {
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%; /* Each column takes the full width on smaller screens */
    padding: 10px 0; /* Reduce padding on smaller screens */
    margin-bottom: 20px; /* Space between stacked columns */
  }
}

.fifth-content-container {
  background-color: #f3f4f6; /* Light background color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  margin: auto;
  max-width: 1400px; /* Adjust width as needed */
}

.cta-text {
  font-size: 34px;
  color: #ffa500; /* Orange color for the text */
  font-weight: bold;
  text-decoration: underline;
}

.cta-button {
  background-color: #ffa500; /* Orange background color for the button */
  border: none;
  padding: 10px;
  border-radius: 50%; /* Circular button */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-button img {
  width: 24px; /* Adjust size as needed */
  height: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .fifth-content-container {
    flex-direction: column;
    text-align: center;
  }

  .cta-button {
    margin-top: 20px;
  }
}

.seventh-content-container {
  /* background-color: #f3f4f6;  */
  display: flex;
  justify-content: center; /* Horizontally center the iframe */
  align-items: center; /* Vertically center the iframe, if necessary */
  padding: 20px;
  margin-bottom: 20px; /* Space between containers */
  position: relative; /* Context for z-index */
  z-index: 1; /* Ensure it's above the background but below other content */
  overflow: hidden; /* Prevents content from spilling out */
}

.seventh-content-container iframe {
  width: 50%; /* Adjusted width */
  height: 1200px; /* Adjust height as needed */
  border: none; /* Removes the default border around iframes */
  z-index: 2; /* Ensures the iframe is above its container's background */
}

.sixth-content-container {
  /* background-color: #f3f4f6; */
  display: flex;
  justify-content: center; /* Horizontally center the iframe */
  align-items: center; /* Vertically center the iframe, if necessary */
  padding: 20px;
  margin-bottom: 20px; /* Space between containers */
  position: relative; /* Context for z-index */
  z-index: 1; /* Ensure it's above the background but below other content */
  overflow: hidden; /* Prevents content from spilling out */
}

.sixth-content-container iframe {
  width: 50%; /* Adjusted width */
  height: 1200px; /* Adjust height as needed */
  border: none; /* Removes the default border around iframes */
  z-index: 2; /* Ensures the iframe is above its container's background */
}

/* Add this if other elements are still overlapping */
.element-that-should-not-overlap {
  z-index: 3; /* Adjust the z-index higher than the iframe's z-index */
  position: relative; /* Position must be set for z-index to take effect */
}

iframe {
  border: none;
  /* You might want to add a box-shadow or border as per your design */
}
