.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
}

.card {
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Updated box-shadow */
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  transition: background-color 0.3s, box-shadow 0.3s;
  flex-basis: calc(20% - 20px); /* Reduced size and adjusted calculation */
  width: 200px; /* Reduced from 300px to 200px */
  margin: 10px;
}

.card-iconHome2 svg {
  font-size: 250px;
  display: block;
  margin: 0 auto;
  padding-bottom: 20px;
}

/* styles.css or your CSS file/module */
/* .large-icon {
  font-size: 110px;
} */

.card:hover {
  background-color: #d3d3d3 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}
.headerAdminCard {
  background-color: #ddd;
  width: 300px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.adminUserNote {
  font-size: 12px; /* Smaller font size for note */
  color: black; /* Lighter text color for note */

  bottom: 10px; /* Positioning from bottom */
  right: 15px; /* Positioning from right */
}

.section {
  margin-bottom: 40px; /* or any space you want between sections */
}

.section-title {
  font-size: 28px; /* Slightly increased font size */
  margin-bottom: 30px; /* Increased bottom margin */
  text-align: center; /* Center aligned text */
  color: #333; /* Dark gray color */
  font-weight: 600; /* Semi-bold weight */
  letter-spacing: 1px; /* Added some letter spacing */
  text-transform: uppercase; /* Uppercase letters */
  padding-top: 20px; /* Padding at the top for spacing */
  border-top: 3px solid #ccc; /* A subtle top border */
}

.tooltip {
  visibility: hidden;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.card:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.card h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.card p {
  font-size: 18px;
}

.card-image {
  width: 100%;
  height: auto;
}

@media (max-width: 1670px) {
  .card-iconHome2 svg {
    font-size: 195px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 1450px) {
  .card-iconHome2 svg {
    font-size: 155px; /* Slightly smaller size for medium screens */
  }
}

/* @media (max-width: 1412px) {
  .card-iconHome2 svg {
    font-size: 195px; 
  }
} */

@media (max-width: 1350px) {
  .card-iconHome2 svg {
    font-size: 140px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 1200px) {
  .card-iconHome2 svg {
    font-size: 120px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 1100px) {
  .card-iconHome2 svg {
    font-size: 100px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 1000px) {
  .card-iconHome2 svg {
    font-size: 60px; /* Slightly smaller size for medium screens */
  }
}

@media (max-width: 768px) {
  .cards-container {
    padding: 20px;
  }

  .card {
    flex-basis: calc(50% - 20px);
    width: auto;
    margin: 5px;
  }

  .section-title {
    font-size: 22px;
    margin-bottom: 20px;
    padding-top: 10px;
  }

  .large-icon {
    font-size: 80px;
  }
}

@media (max-width: 480px) {
  .card {
    flex-basis: 100%;
    margin: 10px 0;
  }

  .section-title {
    font-size: 18px;
  }
}
