/* Login.css */
.login-title,
.login-reset-title {
  text-align: center;
  color: #333;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* high z-index to ensure it's on top */
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 20px;
  max-width: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.login-label {
  align-self: flex-start;
  margin: 10px 0;
  font-size: 1.1em;
}

.login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.sign-in-box {
  width: 30%; /* Adjust width as needed */
  margin: 30px auto; /* Center the box */
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light gray background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: block;
}

.login-button {
  width: 50%;
  padding: 10px;
  border: none;
  background-color: #ff8c00;
  color: white;
  font-size: 1.1em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

.login-reset {
  margin-top: 30px;
  text-align: center;
}

.login-reset-input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-reset-button {
  margin-left: 40px; /* Add this line */
  padding: 10px;
  border: none;
  background-color: #004e9d;
  color: white;
  font-size: 1.1em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-reset-button:hover {
  background-color: #c82333;
}

.forgotPass {
  margin-top: 15px;
  padding-top: 15px;
}
